import ActionButton from '@/components/actionButton';
import Link from 'next/link';
import { useState } from 'react';
import WhitePaperDownload from '../whitepaper-download';

interface PropsIC {
  data: {
    internalTitle: string;
    buttonText: string;
    buttonUrl: string;
    document: {
      url: string;
    };
    showTitle: boolean;
    popupButton?: boolean;
  };
}

const NavigationButton = ({ data }: PropsIC) => {
  const [showWhitePaperDownload, setShowWhitePaperDownload] = useState(false);

  const {
    internalTitle,
    buttonText,
    buttonUrl,
    document,
    showTitle = true,
    popupButton = false
  } = data || {};

  const whitePaperData = {
    internalTitle: internalTitle,
    buttonText: buttonText,
    onClick: () => {
      setShowWhitePaperDownload(true);
    },
    showTitle
  };

  return (
    <>
      <div>
        {popupButton ? (
          <ActionButton data={whitePaperData} />
        ) : (
          <>
            {internalTitle && showTitle && (
              <h3 className="mb-7">{internalTitle}</h3>
            )}
            {buttonUrl && (
              <Link
                href={document?.url ? document.url : buttonUrl}
                target={`${document?.url ? '_blank' : ''}`}
                className="ml-3 green-button group"
              >
                <p className="group-hover:text-brand-green">{buttonText}</p>
              </Link>
            )}
          </>
        )}
      </div>
      {showWhitePaperDownload && (
        <WhitePaperDownload
          data={whitePaperData}
          closeDialog={() => {
            setShowWhitePaperDownload(false);
          }}
        />
      )}
    </>
  );
};

export default NavigationButton;
