import { isEmailValid } from '@/lib/utils';
import Close from '@/public/icons/close-green.svg';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

interface FormData {
  fullName: string;
  email: string;
  privacyNote: boolean;
}

interface WhitePaperDownloadProps {
  data: any;
  closeDialog: () => void;
}

const WhitePaperDownload = ({ data, closeDialog }: WhitePaperDownloadProps) => {
  const { t } = useTranslation();
  const [mounted, setMounted] = useState(false);

  const formDataInitial = {
    fullName: '',
    email: '',
    privacyNote: false
  };
  const [formData, setFormData] = useState<FormData>(formDataInitial);

  const isDisabled =
    !formData.fullName ||
    !isEmailValid(formData.email) ||
    !formData.privacyNote;

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === 'privacyNote' ? checked : value
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    const formDataObject = Object.fromEntries(form.entries());
    let { privacyNote, ...transformedData } = formDataObject;
    transformedData = {
      ...transformedData,
      message: `${formDataObject.fullName} downloaded ${data.buttonText} document`
    };

    try {
      const response = await fetch('/api/send-whitepaper-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(transformedData)
      });
      response && window.open(data.document.url, '_blank');
    } catch (err) {
      console.log('Error while sending email', err);
    } finally {
      setFormData(formDataInitial);
      closeDialog();
    }
  };

  return createPortal(
    <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-50 z-[55] overflow-auto">
      <div className="bg-white p-10 relative">
        <div className="flex flex-col text-base">
          <Image
            src={Close}
            alt="Close"
            width={30}
            height={30}
            className="cursor-pointer ml-auto mb-2"
            onClick={closeDialog}
          />
          <h2 className="text-4xl lg:text-[3rem] mb-8 ">
            {t('whitepaperDownload.heading')}
          </h2>
          <p className="mb-20 xl:mb-32">
            {t('whitepaperDownload.description')}
          </p>
          <form
            onSubmit={handleSubmit}
            className="sm:w-full lg:w-5/6 bg-white flex flex-col gap-10 xl:gap-20"
          >
            <input
              type="text"
              name="fullName"
              placeholder={t('whitepaperDownload.fullName').toString()}
              className="text-black border-b border-black min-w-350 outline-none w-full"
              onChange={handleChange}
            />
            <input
              type="text"
              name="email"
              placeholder={t('whitepaperDownload.email') ?? ''}
              className="text-black border-b border-black min-w-350 outline-none w-full"
              onChange={handleChange}
            />
            <div className="flex items-start gap-4 max-w-[360px]">
              <div className="relative flex">
                <input
                  type="checkbox"
                  id="read"
                  className="peer appearance-none w-6 h-6 border-3 border-black checked:border-0 focus:outline-none cursor-pointer z-10 peer"
                  name="privacyNote"
                  onChange={handleChange}
                  checked={formData.privacyNote}
                />
                <svg
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4 h-4 hidden peer-checked:block"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="4"
                  strokeLinecap="square"
                  strokeLinejoin="miter"
                >
                  <polyline points="20 6 10 17 4 12"></polyline>
                </svg>
              </div>
              <label
                htmlFor="read"
                className="flex flex-col text-base text-gray-700 font-sans flex"
              >
                <Link className="text-black underline" href="/privacy">
                  {t('contactForm.privacyNote')}
                </Link>
                {t('contactForm.personalDataStorage')}
              </label>
            </div>
            <button className="green-button self-start" disabled={isDisabled}>
              <p>{data.buttonText}</p>
            </button>
          </form>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default WhitePaperDownload;
