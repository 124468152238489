interface ActionButtonProps {
  data: ActionProps;
}

interface ActionProps {
  internalTitle: string;
  buttonText: string;
  showTitle?: boolean;
  onClick: () => void;
}

const ActionButton = ({ data }: ActionButtonProps) => {
  const { internalTitle, buttonText, onClick, showTitle = true } = data;

  return (
    <div>
      {showTitle && internalTitle && <h3 className="mb-7">{internalTitle}</h3>}
      <button className="ml-3 green-button group" onClick={onClick}>
        {buttonText && (
          <p className="group-hover:text-brand-green">{buttonText}</p>
        )}
      </button>
    </div>
  );
};

export default ActionButton;
